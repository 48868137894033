import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { PasswordInput, Button, IconEye, IconEyeCrossed } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const ExternalButtonExample = () => {
  const [revealPassword, setRevealPassword] = React.useState(false);
  return <div style={{
    display: 'grid',
    gridTemplateColumns: '320px 240px',
    gap: 'var(--spacing-s)'
  }}>
      <PasswordInput label="Password" id="password-input-3" includeShowPasswordButton={false} helperText="Assistive text" type={revealPassword ? 'text' : 'password'} required style={{
      maxWidth: '320px'
    }} mdxType="PasswordInput" />
      <div style={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
    }}>
        <Button variant="supplementary" onClick={() => setRevealPassword(!revealPassword)} iconLeft={revealPassword ? <IconEyeCrossed aria-hidden mdxType="IconEyeCrossed" /> : <IconEye aria-hidden mdxType="IconEye" />} mdxType="Button">
          {revealPassword ? 'Hide password' : 'Show password'}
        </Button>
      </div>
    </div>;
};
export const _frontmatter = {
  "slug": "/components/password-input",
  "title": "PasswordInput",
  "navTitle": "PasswordInput"
};
const layoutProps = {
  ExternalButtonExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <PasswordInput concealPasswordButtonAriaLabel="Hide password" helperText="Assistive text" id="password-input-1" includeShowPasswordButton label="Password" revealPasswordButtonAriaLabel="Show password" required style={{
        maxWidth: '320px'
      }} mdxType="PasswordInput" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`A label should always be provided with password fields.`}</li>
      <li parentName="ul">{`If possible, validate input in client-side real-time and provide the user with immediate feedback. See `}<InternalLink href="/patterns/forms/form-validation" mdxType="InternalLink">{`HDS Form validation pattern page`}</InternalLink>{` for more information and best practices with password fields.`}</li>
      <li parentName="ul">{`Password fields should always be mandatory. It is recommended to mark it as a required field.`}</li>
      <li parentName="ul">{`Verify that "paste" functionality, browser password helpers, and external password managers are allowed for the input.`}</li>
      <li parentName="ul">{`HDS password input provides a way to mask/unmask the password. Do not disabled this functionality without a good reason.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`A default HDS password input includes an icon to mask and unmask the password.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <PasswordInput concealPasswordButtonAriaLabel="Hide password" helperText="Assistive text" id="password-input-2" includeShowPasswordButton label="Password" revealPasswordButtonAriaLabel="Show password" required style={{
        maxWidth: '320px'
      }} mdxType="PasswordInput" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-an-external-button",
      "style": {
        "position": "relative"
      }
    }}>{`With an external button`}<a parentName="h4" {...{
        "href": "#with-an-external-button",
        "aria-label": "with an external button permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`If necessary, the mask/unmask icon can be removed and the feature controlled with an external button element.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <ExternalButtonExample mdxType="ExternalButtonExample" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      